import Base from '../../_config/base';
import { toggleClass, setActive } from '../../_config/utils';

class Select extends Base {
  constructor(el) {
    super(el);
    const dropdownMain = el.querySelector('.dropdown__main');
    const dropdownMainInput = el.querySelector('.dropdown__main input');
    const dropdownContainer = el.querySelector('.select__dropdown--container');
    const optionList = el.querySelectorAll('.select__radio__wrapper input[type="radio"]');

    if (dropdownMain !== null) {
      const toggleDropdown = () => {
        toggleClass(dropdownContainer, 'd-none');
        toggleClass(dropdownMain, 'active');
      };

      dropdownMain.addEventListener('click', toggleDropdown);
    }

    [...optionList].forEach((option) => {
      option.addEventListener('change', () => {
        dropdownMainInput.value = option.value;
        dropdownMain.querySelector('label').classList.add('selected');
        setActive(option.closest('.select__radio__wrapper'));
        toggleClass(dropdownContainer, 'd-none');
        toggleClass(dropdownMain, 'active');
      });
    });
  }
}

Select.className = 'Select';
export default Select;
